.banner {
    
    background-image: url(https://www.maigesellschaft-stommeln.de/wp-content/uploads/2020/03/banner-2.png);
    background-size: cover;
    background-position: right top;
    background-repeat: no-repeat;
    min-height: 250px;
}
.App {
    text-align: center;
}


/* .App-link {
    color: #61dafb;
} */

body {
    background-image: url(https://www.maigesellschaft-stommeln.de/wp-content/uploads/2020/03/lgog_lion.png), url(https://www.maigesellschaft-stommeln.de/wp-content/uploads/2020/03/lgog_tree-5.png);
    background-size: auto;
    background-position: right bottom, left bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.vid {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}

.vid > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 90vh;
}

#loader {
    /*background: #fff;*/
    color: transparent;
    /*position: absolute;*/
    /*top: 0;
    bottom: 0;
    right: 0;
    left: 0;*/
    /*text-indent: -9999px;*/
    z-index: -1;
    margin-top: 10%;
}

#logo-loader {
    /*position: absolute;*/
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    opacity: 1;
    /*top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;*/
    height: 200px;
    width: 200px;
}

@keyframes gen-animation-keyframes {
    0% {
        transform: rotateY(0deg);
        opacity: 1;
        animation-timing-function: linear;
    }

    25% {
        transform: rotateY(-90deg);
        opacity: 1;
        animation-timing-function: linear;
    }

    50% {
        transform: rotateY(-180deg);
        opacity: 1;
        animation-timing-function: linear;
    }

    75% {
        transform: rotateY(-270deg);
        opacity: 1;
        animation-timing-function: linear;
    }


    100% {
        transform: rotateY(-360deg);
        opacity: 1;
        animation-timing-function: linear;
    }
}

.gen-animation {
    animation: 4.0s linear 0s infinite normal forwards running gen-animation-keyframes;
}

.Countdown{
    margin: 10px auto;
    padding-bottom: 20px;
  }
  
  .Countdown-col{
    display: inline-block;
  }
  
  .Countdown-col-element{
    display: inline-block;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
  }
  
  .Countdown-col-element strong{
    font-size: 50px;
  }